<template>
    <div>
        <div class="row" v-if="vehicles.data">
            <div class="col-md-3 mb-3" v-for="vehicle in vehicles.data">
                <div class="card card bg-dark text-white">
                    <div class="card-body">
                        <h5 class="card-title text-center">{{ vehicle.title }}</h5>
                        <img style="height:200px" class="card-img-top" :src="vehicle.image_url??'https://via.placeholder.com/150x150.png'" alt="Card image cap">
                        <p class="card-text"><b>Price:</b>
                            {{ vehicle.rent }} $
                        </p>
                        <div class="d-flex justify-content-between mb-2">
                            <div class="input-group ">
                                <div class="input-group-text p-2"><i class="fa fa-user"></i><span
                                    class="ps-1">max</span></div>
                                <div class="input-group-text">{{ vehicle.max_passenger }}</div>
                            </div>
                            <div class="input-group ">
                                <div class="input-group-text p-2"><i class="fa fa-briefcase"></i><span
                                    class="ps-1">max</span></div>
                                <div class="input-group-text ">{{ vehicle.max_luggage }}</div>
                            </div>
                        </div>
                        <!--                        <button type="button" class="btn btn-outline-danger w-100">Request Quote</button>-->
                        <button type="button" @click="booking(vehicle)" class="btn btn-secondary w-100 mt-2">
                            {{ vehicle.rent != 0 ? "Book" : "Quote" }}
                        </button>
                    </div>

                </div>
            </div>

        </div>
        <div>
            <Pagination :size="'small'" :align="'right'" :show-disabled="true" :limit="3" :data="vehicles"
                        @pagination-change-page="getVehicles"/>
        </div>
        <div>
            <loading :active="isLoading"
                     :can-cancel="false"
                     :on-cancel="onCancel"
                     :is-full-page="fullPage"></loading>
        </div>
    </div>
</template>

<script>
import LaravelVuePagination from 'laravel-vue-pagination';
import ApiService           from "@/services/api.service";
// Import component
import Loading              from 'vue3-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import JwtService           from "@/services/jwt.service";

export default {
    name      : "SelectVehicle",
    components: {
        'Pagination': LaravelVuePagination,
        Loading
    },
    data      : () => ({
        vehicles : [],
        isLoading: false,
        fullPage : true
    }),
    computed  : {
        rideInfo() {
            return this.$store.getters.getRideInfo;
        }
    },
    mounted() {
        if (this.rideInfo.service_type) {
            this.getVehicles();
        }
    },
    methods: {
        getVehicles(page = 1) {
            this.isLoading = true;
            ApiService.get(`/vehicle?page=${page}&rideInfo=${JSON.stringify(this.rideInfo)}`).then((res) => {
                this.vehicles  = res.data;
                this.isLoading = false;
            }).catch(error => {
                this.isLoading = false;
                // TODO:: need to error handaling
                console.log(error);
            })
        },
        booking(vehicle) {
            const token = JwtService.getToken();
            let step    = token ? 4 : 3
            this.$store.commit('setVehicle', vehicle);
            this.$store.commit('setInfoState', step);
            this.$emit('infoState');
        }
    }
}
</script>

<style>
.pagination {
    boder-color: red !important;
}

.page-item .page-link {
    color: #fff !important;
    background: #565e64 !important;
}
</style>
