<template>
    <div class="mt-5">
        <div class="container">
            <router-view/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'App',
}
</script>
