const webRoutes = [
    {
        path     : '',
        name     : 'Home',
        component: () => import('../Home'),
        meta     : {
            title: 'Home'
        }
    },
    {
        path     : '/about',
        name     : 'About',
        component: () => import('../About'),
        meta     : {
            title: 'About'
        }
    },
    {
        path     : '/booking-cancel',
        name     : 'BookingCancel',
        component: () => import('./BookingCancel.vue'),
        meta     : {
            title: 'Booking Cancel'
        }
    },
];

export default webRoutes;
