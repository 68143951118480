<template>
    <div id="payment">
        <div class="custom-control custom-radio">
            <input value="stripe" id="stripe" v-model="payment_type" type="radio" class="custom-control-input" required>
            <label class="custom-control-label" for="stripe">Stripe</label>
        </div>
        <div class="custom-control custom-radio">
            <input value="paypal" id="paypal" v-model="payment_type" type="radio" class="custom-control-input" required>
            <label class="custom-control-label" for="paypal">PayPal</label>
        </div>

        <div v-show="payment_type == 'stripe'" ref="cardRef"></div>
        <button @click="createToken" class="btn btn-secondary mt-5">Submit Request</button>
        <loading :active="isLoading"
                 :can-cancel="false"
                 :on-cancel="onCancel"
                 :is-full-page="fullPage"></loading>
    </div>
</template>
<script>
import {ref, onMounted, computed} from 'vue';
import {useStore}                 from "vuex";
// Import component
import Loading                    from 'vue3-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import ApiService                 from "@/services/api.service";

export default {
    components: {Loading},
    setup(props, {emit}) {
        let payment_type = ref('stripe');
        let stripe_info  = ref(null);
        let stripe       = ref(null);
        let elements     = ref(null);
        let card         = ref(undefined);
        let cardRef      = ref(null);
        const isLoading  = ref(false);
        const fullPage   = ref(true);
        const store      = useStore();
        onMounted(() => {
            stripe   = Stripe(process.env.VUE_APP_STRIPE_CLIENT_ID);
            elements = stripe.elements();
            card     = elements.create('card', {
                style: {
                    base: {
                        iconColor      : '#fff',
                        color          : '#fff',
                        backgroundColor: '#343a40',
                        lineHeight     : '3',
                        '::placeholder': {
                            color: '#fff',
                        },
                    },
                }
            });
            card.mount(cardRef.value);
        });
        const rideInfoStore = computed(() => {
            return store.getters.getRideInfo;
        });
        const vehicleInfo   = computed(() => {
            return store.getters.getVehicle;
        });
        const guestUser     = computed(() => {
            return store.getters.getGuestUser;
        });
        const authUser      = computed(() => {
            return store.state.auth;
        });
        const createToken   = () => {
            isLoading.value  = true;
            let _vehicleInfo = vehicleInfo.value;
            let rideInfo     = rideInfoStore.value;

            if (Object.keys(_vehicleInfo).length && Object.keys(rideInfo).length) {
                if (payment_type.value == 'stripe') {
                    stripe.createToken(card).then(function (result) {
                            if (result.error) {
                                Toast.fire({
                                    icon : 'error',
                                    title: result?.error?.message
                                });
                                isLoading.value = false;
                                return;
                            }
                            stripe_info.value = result
                            finalSubmit()
                            // Access the token with result.token
                        }
                    ).catch((err) => {
                        isLoading.value = false;
                        Toast.fire({
                            icon : 'error',
                            title: err
                        })
                    });
                } else {
                    finalSubmit()
                }

            } else {
                isLoading.value = false;
                Toast.fire({
                    icon : 'error',
                    title: 'please fill up all the carefully'
                })
            }
        }

        const finalSubmit = () => {
            ApiService.post(`/booking`, {
                    vehicle     : vehicleInfo.value,
                    rideInfo    : rideInfoStore.value,
                    strip       : stripe_info.value,
                    payment_type: payment_type.value,
                    user        : authUser.value?.user,
                    guestUser   : guestUser.value
                }
            ).then((res) => {
                // TODO:: empty ride form and vehicle list;

                // todo::use from composable
                // store.commit('setInfoState', 1);
                //setinfoStateComposable(2)
                let {payment} = res.data
                if (payment_type.value == 'paypal' && payment.status == 'success') {
                    store.commit('setRideInfo', {});
                    store.commit('setVehicle', {});
                    emit('completeBooking');
                    window.location.href = payment.url;
                }else {
                    store.commit('setRideInfo', {});
                    store.commit('setVehicle', {});
                    emit('completeBooking');
                    isLoading.value = false;
                    Toast.fire({
                        icon : 'success',
                        title: 'Your request submitted successfully.'
                    })
                }
                //window.location.reload();
            }).catch(errors => {
                let err = '';
                if (errors?.response?.status == 422) {
                    Object.values(errors?.response?.data?.errors).map((error) => {
                        error.map((item) => {
                            err += item + '\n';
                        })
                    })
                } else {
                    err = errors?.response?.data?.message
                }
                isLoading.value = false;
                Toast.fire({
                    icon : 'error',
                    title: err
                })
            })
        }
        const onCancel    = () => {
            console.log('User cancelled the loader.');
            //because the props is single flow direction, you need to set isLoading status normally.
            isLoading.value = false;
        }
        return {
            payment_type,
            stripe,
            elements,
            card,
            cardRef,
            createToken,
            vehicleInfo,
            guestUser,
            rideInfoStore,
            authUser,
            isLoading,
            fullPage,
            onCancel
        }
    }
}
</script>
<style>

</style>
