const AdminRoutes = [
    {
        path     : '/dashboard',
        name     : 'adminDashboard',
        component: () => import('./Dashboard'),
        meta     : {
            title: 'Admin Dashboard'
        }
    },{
        path     : '/booking',
        name     : 'bookingIndex',
        component: () => import('./pages/booking/Index'),
        meta     : {
            title: 'Booking'
        }
    },{
        path     : '/booking/:id',
        name     : 'bookingShow',
        component: () => import('./pages/booking/Show'),
        meta     : {
            title: 'Booking Details'
        }
    }
];

export default AdminRoutes;
