<template>
    <div class="home">
        <div class="row">
            <div class="col-md-12">
                <div class="card bg-dark text-white">
                    <div class="card-header bg-secondary text-white">
                        Step 1: Ride Info
                        <button class="btn btn-danger float-end" v-if="infoState != 1" @click="updateInfoState(1)">Edit
                        </button>
                    </div>
                    <div class="card-body" id="ride-info" v-if="infoState == 1">
                        <ride-info ref="rideInfo" @infoState="setInfoState"/>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-5">
                <div class="card bg-dark text-white">
                    <div class="card-header bg-secondary text-white">
                        Step 2: Select Vehicle
                        <button class="btn btn-danger float-end" v-if="infoState > 2" @click="updateInfoState(2)">Edit
                        </button>
                    </div>
                    <div class="card-body" id="select-vehicle" v-if="infoState == 2">
                        <select-vehicle @infoState="setInfoState"/>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-5">
                <div class="card bg-dark text-white">
                    <div class="card-header bg-secondary text-white">
                        Step 3: Login
                        <button class="btn btn-danger float-end" v-if="infoState > 3" @click="updateInfoState(3)">Edit
                        </button>
                    </div>
                    <div class="card-body" v-if="infoState == 3">
                        <div class="row">
                            <div class="col-md-6">
                                <LoginComponent @infoState="setInfoState" call_from="homePage"/>
                            </div>
                            <div class="col-md-6">
                                <GuestUser @infoState="setInfoState" v-if="!user?.id"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="vehicleInfo && vehicleInfo.rent != 0" class="col-md-12 mt-5">
                <div class="card bg-dark text-white">
                    <div class="card-header bg-secondary text-white">
                        Step 4: Payment
                    </div>
                    <div class="card-body" v-if="infoState == 4">
                        <stripe-payment @completeBooking="completeBooking"/>
                    </div>
                </div>
            </div>
        </div>
        <loading :active="isLoading"
                 :can-cancel="false"
                 :on-cancel="onCancel"
                 :is-full-page="fullPage"></loading>
    </div>
</template>

<script>
// @ is an alias to /src
import {}                         from 'bootstrap'
import RideInfo                   from "@/components/home/RideInfo";
import SelectVehicle              from "@/components/home/SelectVehicle";
import StripePayment              from "@/components/StripePayment";
import {onMounted, ref, computed} from 'vue'
import {useStore}                 from "vuex";
import setInfoStateComposable     from "@/composables/InfoState";
import LoginComponent             from "@/components/home/Login";
import GuestUser                  from "@/components/home/GuestUser";
import ApiService                 from "@/services/api.service";
import Loading                    from 'vue3-loading-overlay';
export default {
    name      : 'Home',
    components: {
        Loading,
        GuestUser,
        LoginComponent,
        RideInfo,
        SelectVehicle,
        StripePayment
    },
    setup() {
        const store     = useStore();
        const rideInfo  = ref(null);
        let refRideInfo = ref(null);
        const isLoading  = ref(false);
        const fullPage   = ref(true);
        const rideInfoStore = computed(() => {
            return store.getters.getRideInfo;
        });
        const guestUser     = computed(() => {
            return store.getters.getGuestUser;
        });
        const authUser      = computed(() => {
            return store.state.auth;
        });

        let infoState   = ref(3);
        onMounted(() => {
            setInfoState();
        });
        const setInfoState    = () => {
            if (store.getters.getInfoState == 4 && vehicleInfo.value.rent == 0){
                finalSubmit()
            }
            infoState.value = store.getters.getInfoState;
        }

        const finalSubmit = () => {
            isLoading.value  = true;
            ApiService.post(`/booking`, {
                is_quote : true,
                vehicle  : vehicleInfo.value,
                rideInfo : rideInfoStore.value,
                user     : authUser.value?.user,
                guestUser: guestUser.value
                }
            ).then((res) => {
                store.commit('setRideInfo', {});
                store.commit('setVehicle', {});
                completeBooking();
                isLoading.value = false;
                Toast.fire({
                    icon : 'success',
                    title: 'Your request submitted successfully.'
                })
            }).catch(errors => {
                let err = '';
                if (errors?.response?.status == 422) {
                    Object.values(errors?.response?.data?.errors).map((error) => {
                        error.map((item) => {
                            err += item + '\n';
                        })
                    })
                } else {
                    err = errors?.response?.data?.message
                }
                isLoading.value = false;
                Toast.fire({
                    icon : 'error',
                    title: err
                })
            })
        }

        const updateInfoState = (state) => {

            // todo::use from composable
            store.commit('setInfoState', state);
            //setInfoStateComposable(state)
            setInfoState();
        }
        const completeBooking = () => {
            //console.log(refRideInfo)
            //refRideInfo.value.clearForm();
            updateInfoState(1)
        }
        const vehicleInfo   = computed(() => {
            return store.getters.getVehicle;
        });
        const user = computed(() => {
            return store.state.auth.user;
        });
        const onCancel    = () => {
            console.log('User cancelled the loader.');
            //because the props is single flow direction, you need to set isLoading status normally.
            isLoading.value = false;
        }
        return {
            isLoading,
            fullPage,
            onCancel,
            vehicleInfo,
            infoState,
            setInfoState,
            updateInfoState,
            completeBooking,
            user
        }
    }
}
</script>

<style>
body {
    background: #212529 !important;
}
</style>
