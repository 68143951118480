import { createRouter, createWebHistory } from 'vue-router'
import NotFound                           from '@/components/NotFound.vue';
import WebLayout                          from "@/views/web/Layout";
import webRoutes                          from "@/views/web/_routes";
//Admin Section
import AdminRoutes                        from "@/views/admin/_routes";
import AdminLayout                        from "@/views/admin/Layout";
//Auth Section
import AuthRoutes                         from "@/views/auth/_routes";
import Home                               from "@/views/Home";

const routes = [
     //Frontend routing
     {
         path     : '/',
         name     : 'website',
         component: WebLayout,
         children : webRoutes
     },
    //Auth Section routing
    {
        path     : '/',
        name     : 'Authentication',
        component: WebLayout,
        children : AuthRoutes
    },
    //Admin Section routing
    {
        path     : '/',
        component: AdminLayout,
        children : AdminRoutes,
        meta     : {
            requireAuth: true,
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
