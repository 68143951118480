import {createStore}        from 'vuex'
import createPersistedState from "vuex-persistedstate";
import auth                 from "@/store/moduels/auth";

const store = createStore({
    plugins  : [createPersistedState()],
    state    : {
        rideInfo : {},
        vehicle  : {},
        guestUser: {},
        infoState: 1
    },
    getters  : {
        getRideInfo : state => {
            return state.rideInfo;
        },
        getVehicle  : state => {
            return state.vehicle;
        },
        getInfoState: state => {
            return state.infoState;
        },
        getGuestUser: state => {
            return state.guestUser;
        }
    },
    mutations: {
        setRideInfo(state, payload) {
            state.rideInfo = payload;
        },
        setVehicle(state, payload) {
            state.vehicle = payload;
        },
        setInfoState(state, payload) {
            state.infoState = payload;
        },
        setGuestUser(state, payload) {
            state.guestUser = payload;
        }
    },
    actions  : {},
    modules  : {
        auth: auth,
    }
});

export default store
