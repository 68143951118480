<template>
    <div id="sidebar" class="bg-purple">
        <div class="h4 text-white">Account</div>
        <ul>
            <li :class="[route.name=='adminDashboard' ? 'active':'']">
                <router-link class="text-decoration-none d-flex align-items-start"
                             :to="{name:'adminDashboard'}">
                    <div class="fas fa-box pt-2 me-3"></div>
                    <div class="d-flex flex-column">
                        <div class="link">My Account</div>
                        <div class="link-desc">View & Manage my account</div>
                    </div>
                </router-link>
            </li>
            <li :class="[route.name=='bookingIndex' || route.name=='bookingShow'  ? 'active':'']">
                <router-link class="text-decoration-none d-flex align-items-start"
                             :to="{name:'bookingIndex'}">
                    <div class="fas fa-box pt-2 me-3"></div>
                    <div class="d-flex flex-column">
                        <div class="link">My Booking</div>
                        <div class="link-desc">View & Manage Booking</div>
                    </div>
                </router-link>
            </li>
            <!--            <li>
                    <a href="#" class="text-decoration-none d-flex align-items-start">
                        <div class="far fa-user pt-2 me-3"></div>
                        <div class="d-flex flex-column">
                            <div class="link">My Profile</div>
                            <div class="link-desc">Change your profile details & password</div>
                        </div>
                    </a>
                </li>
        <li>
                    <a href="#" class="text-decoration-none d-flex align-items-start">
                        <div class="far fa-address-book pt-2 me-3"></div>
                        <div class="d-flex flex-column">
                            <div class="link">Address Book</div>
                            <div class="link-desc">View & Manage Addresses</div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="#" class="text-decoration-none d-flex align-items-start">
                        <div class="fas fa-headset pt-2 me-3"></div>
                        <div class="d-flex flex-column">
                            <div class="link">Help & Support</div>
                            <div class="link-desc">Contact Us for help and support</div>
                        </div>
                    </a>
                </li>-->
        </ul>
    </div>
</template>

<script setup>
import { useRoute } from 'vue-router';

const route = useRoute();
</script>

<style scoped>

</style>
