import JwtService          from "@/services/jwt.service";
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import router              from "@/router";
import store               from "@/store";

export default function logout() {
    const token = JwtService.getToken();
    if (typeof token != "undefined") {
        ApiService.post('/logout').then(res => {
            JwtService.destroyToken();
            store.commit("auth/SETUSER", {});
            store.commit('setGuestUser', {});
            router.push({name: "Home"});
            NotificationService.success(res.data.message);
        }).catch(error => {
            NotificationService.error(error.response.data.message);
        })
    }
}
