<template>
<h2>404</h2>
</template>

<script>
export default {
    name: "NotFound"
}
</script>

<style scoped>

</style>
