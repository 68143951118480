<template>
    <div  v-if="user?.name && token">
        <ul class="list-group bg-secondary">
            <li class="list-group-item d-flex justify-content-between bg-secondary">
                <div>Profile</div>
                <div>
                    <router-link :to="{name:'adminDashboard'}" class="btn btn-info text-decoration-none me-2"> Profile
                    </router-link>
                    <button class="btn btn-danger" @click="logout()">Logout</button>
                </div>
            </li>
            <li class="list-group-item bg-secondary">Name: {{user.name}}</li>
            <li class="list-group-item bg-secondary">Email: {{user.email}}</li>
        </ul>

        <button class="btn btn-primary mt-3" @click="stepUpdate()" type="button">Next>></button>
    </div>
    <form v-else @submit.prevent="login()" class="needs-validation" novalidate>
        <h3 class="text-center mb-4 text-primary">Login</h3>
        <div class="mb-3">
            <input v-model="form.email" type="email" class="form-control text-center"
                   :class="{'is-invalid' : error}"
                   id="email" required placeholder="USERNAME">
        </div>
        <div class="mb-3">
            <input v-model="form.password" type="password" class="form-control text-center"
                   :class="{'is-invalid' : error}" id="password" required placeholder="PASSWORD">
        </div>
        <div class="col-12 text-center">
            <button type="submit" class="btn btn-primary w-100 text-light">Login</button>
            <router-link :to="{name:'register'}" class="mt-2 text-decoration-none"> Create an account
            </router-link>
        </div>
        <div v-if="error" class="invalid-feedback d-block text-center">
            {{ error_msg }}
        </div>
    </form>

</template>

<script>
import ApiService          from "@/services/api.service";
import JwtService          from "@/services/jwt.service";
import NotificationService from "@/services/notification.service";
import store               from "@/store";
import logout              from '@/composables/Auth';

export default {
    name : "LoginComponent",
    props: {
        call_from: {
            type   : String,
            default: 'login'
        }
    },
    data : () => ({
        token    : '',
        form     : {
            email   : "",
            password: ""
        },
        error    : false,
        error_msg: '',
    }),
    computed: {
        user() {
            return this.$store.state.auth.user;
        },
    },
    mounted() {
        this.getUser()
    },
    methods: {
        getUser(){
            this.token = JwtService.getToken();
            if (this.token){
                ApiService.get('/user').then(response => {
                    store.commit("auth/GETUSER", response.data.data);
                    store.commit('setGuestUser', {});
                }).catch(error => {
                    JwtService.destroyToken();
                })
            }
        },
        stepUpdate(){
            this.$store.commit('setInfoState', 4);
            this.$emit('infoState');
        },
        logout(){
          logout();
        },
        login() {
            this.error = false;
            ApiService.post('/login', this.form).then(({data}) => {
                JwtService.saveToken(data.data.access_token);
                localStorage.setItem("expires_at", data.data.expires_at);
                ApiService.init();
                this.stepUpdate();
                this.getUser();
                NotificationService.success(data.message);
            }).catch(error => {
                this.error     = true;
                this.error_msg = error.response.data.message;
                NotificationService.error(this.error_msg);
            });
        }
    }
};
</script>
