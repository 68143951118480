<template>
    <form @submit.prevent="register()" class="needs-validation border-start ps-5" novalidate>
        <h3 class="text-center mb-4 text-primary">Guest User</h3>
        <div class="mb-3">
            <input v-model="guestUser.name" type="text" class="form-control text-center"
                   :class="{'is-invalid' : v$?.guestUser?.name?.$error}"
                   id="name" required placeholder="NAME">
            <div class="text-danger" v-if="v$?.guestUser?.name?.$error">This {{
                    v$?.guestUser?.name?.required?.$message
                }}
            </div>
        </div>
        <div class="mb-3">
            <input v-model="guestUser.email" type="email" class="form-control text-center"
                   :class="{'is-invalid' : v$?.guestUser?.email?.$error}"
                   id="email" required placeholder="EMAIL">
            <div class="text-danger" v-if="v$?.guestUser?.email?.$error ">This {{
                    v$?.guestUser?.email?.required?.$message
                }} or <br> {{v$?.guestUser?.email?.email?.$message}}
            </div>
        </div>
        <div class="mb-3">
            <input v-model="guestUser.phone" type="phone" class="form-control text-center"
                   :class="{'is-invalid' : v$?.guestUser?.phone?.$error}"
                   id="phone" required placeholder="Phone">
            <div class="text-danger" v-if="v$?.guestUser?.phone?.$error ">This {{
                    v$?.guestUser?.phone?.required?.$message }}
            </div>
        </div>
        <div class="col-12 text-center">
            <button type="submit" class="btn btn-primary w-100 text-light">Continue as guest</button>
        </div>
    </form>
</template>

<script>
import {ref}             from 'vue';
import useVuelidate      from "@vuelidate/core";
import {email, required} from "@vuelidate/validators";
import {useStore}        from "vuex";
export default {
    name: "GuestUser",
    setup(props, {emit}) {
        const guestUser = ref({
            name : "",
            email: "",
            phone: "",
        });
        const v$        = useVuelidate();
        const store = useStore()
        const register  = async () => {
            const isFormCorrect = await v$.value.$validate()
            if (!isFormCorrect) return
            store.commit('setGuestUser', guestUser);
            store.commit('setInfoState', 4);
            emit('infoState');
        }
        return {
            guestUser,
            register,
            v$
        };
    },
    validations() {
        return {
            guestUser: {
                name : {required},
                email: {required, email},
                phone : {required},
            }
        }
    }
};
</script>

<style scoped>

</style>
