<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <form class="row g-3" @submit.prevent="chooseVehicle">
                    <div :class="[rideInfo.service_type === 'Hourly' ? 'col-md-6' : 'col-md-12']">
                        <label for="serviceType" class="form-label">Select Service Type</label>
                        <select id="serviceType" class="form-select" v-model="rideInfo.service_type"
                                @change="rideInfo.service_type !== 'Hourly'? rideInfo.hours='' : rideInfo.hours">
                            <option value="To Airport">To Airport</option>
                            <option value="From Airport">From Airport</option>
                            <option value="Point-to-Point">Point-to-Point</option>
                            <option value="Hourly">Hourly</option>
                        </select>
                        <div class="text-danger" v-if="v$?.rideInfo?.service_type?.$error">This {{
                                v$?.rideInfo?.service_type?.required?.$message
                            }}
                        </div>
                    </div>
                    <div class="col-md-6" v-if="rideInfo.service_type === 'Hourly'">
                        <label for="hours" class="form-label">Hours</label>
                        <input type="number" class="form-control" id="hours"
                               placeholder="Hours" v-model="rideInfo.hours"
                               autocomplete="off">
                        <div class="text-danger" v-if="v$?.rideInfo?.hours?.$error">This
                            {{ v$?.rideInfo?.hours?.required?.$message }}
                        </div>
                    </div>
                    <div class="col-md-6" v-if="rideInfo.service_type === 'From Airport'">
                        <label for="flight_number" class="form-label">Flight number</label>
                        <input placeholder="Your flight number" type="text" class="form-control" id="flight_number"
                               v-model="rideInfo.flight_number">
                        <div class="text-danger" v-if="v$?.rideInfo?.flight_number?.$error">This {{
                                v$?.rideInfo?.flight_number?.required?.$message
                            }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label for="pickUpDate" class="form-label">Pick-Up Date</label>
                        <input type="date" class="form-control" id="pickUpDate" v-model="rideInfo.pick_up_date">
                        <div class="text-danger" v-if="v$?.rideInfo?.pick_up_date?.$error">This {{
                                v$?.rideInfo?.pick_up_date?.required?.$message
                            }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label for="pickUpTime" class="form-label">Pick-Up Time</label>
                        <input type="time" class="form-control" id="pickUpTime" v-model="rideInfo.pick_up_time">
                        <div class="text-danger" v-if="v$?.rideInfo?.pick_up_time?.$error">This {{
                                v$?.rideInfo?.pick_up_time?.required?.$message
                            }}
                        </div>
                    </div>
                    <div class="col-12">
                        <label for="pickUpLocation" class="form-label">Pick-Up Location</label>
                        <input type="text" class="form-control" id="pickUpLocation"
                               placeholder="Pick-Up Location" v-model="rideInfo.pick_up_location"
                               @keyup="origin('pickup')" autocomplete="off">
                        <div class="text-danger" v-if="v$?.rideInfo?.pick_up_location?.$error">This
                            {{ v$?.rideInfo?.pick_up_location?.required?.$message }}
                        </div>
                    </div>
                    <div class="col-6" v-if="rideInfo.stop_points?.length">
                        <label class="form-label">Stop Point</label>
                        <div class="text-danger" v-if="v$?.rideInfo?.stop_points?.value?.$error">
                            {{ v$?.rideInfo?.stop_points?.value?.required?.$message }}
                        </div>
                        <div class="mb-2 d-flex" v-for="(stop_point, index) in rideInfo?.stop_points" :key="index">
                            <input type="text" class="form-control"
                                   @keyup="stopLocationMap(`stop_point${index}`, index)"
                                   :id="`stop_point${index}`" v-model="stop_point.value" placeholder="Stop Point"
                                   autocomplete="off"/>
                            <span @click="removeStopPoint(index)" class="btn btn-danger"
                                  style="margin: auto; margin-left: 10px !important; "><i
                                class="fa fa-times"></i></span>
                        </div>
                    </div>
                    <a class="text-danger" type="button" @click="addStopPoint"><i class="fa fa-plus"></i> Add Stop</a>
                    <div class="col-12">
                        <label for="dropUpLocation" class="form-label">Drop Location</label>
                        <input type="text" class="form-control" id="dropUpLocation" @keyup="origin('drop')"
                               placeholder="Drop Location" v-model="rideInfo.drop_location" autocomplete="off">
                        <div class="text-danger" v-if="v$?.rideInfo?.drop_location?.$error">This {{
                                v$?.rideInfo?.drop_location?.required?.$message
                            }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label for="passengerNumber">Number of Passengers</label>
                        <div class="input-group">
                            <div class="input-group-text"><i class="fa fa-users"></i></div>
                            <div class="input-group-text" role="button"
                                 @click="crement('number_of_passenger', 'decrement')">-
                            </div>
                            <input type="number" class="form-control" id="passengerNumber" placeholder="0"
                                   v-model="rideInfo.number_of_passenger" oninput="this.value = Math.abs(this.value)">
                            <div class="input-group-text" role="button"
                                 @click="crement('number_of_passenger', 'increment')">+
                            </div>
                        </div>
                        <div class="text-danger" v-if="v$?.rideInfo?.number_of_passenger?.$error">
                            {{ v$?.rideInfo?.number_of_passenger?.minValue?.$message }}
                        </div>

                    </div>
                    <div class="col-md-6">
                        <label for="luggageCount">Luggage Count</label>
                        <div class="input-group">
                            <div class="input-group-text"><i class="fa fa-briefcase"></i></div>
                            <div class="input-group-text" role="button" @click="crement('luggage_count', 'decrement')">-
                            </div>
                            <input type="number" class="form-control" id="luggageCount" placeholder="0"
                                   v-model="rideInfo.luggage_count" oninput="this.value = Math.abs(this.value)">
                            <div class="input-group-text" role="button" @click="crement('luggage_count', 'increment')">+
                            </div>
                        </div>
                        <div class="text-danger" v-if="v$?.rideInfo?.luggage_count?.$error">This {{
                                v$?.rideInfo?.luggage_count?.required?.$message
                            }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label for="luggageCount">Child Count</label>
                        <div class="input-group">
                            <div class="input-group-text"><i class="fa fa-briefcase"></i></div>
                            <div class="input-group-text" role="button" @click="crement('child_count', 'decrement')">-
                            </div>
                            <input type="number" class="form-control" id="childCount" placeholder="0"
                                   v-model="rideInfo.child_count" oninput="this.value = Math.abs(this.value)">
                            <div class="input-group-text" role="button" @click="crement('child_count', 'increment')">+
                            </div>
                        </div>
                        <div class="text-danger" v-if="v$?.rideInfo?.child_count?.$error">This {{
                                v$?.rideInfo?.child_count?.required?.$message
                            }}
                        </div>
                    </div>

                    <div class="col-12">
                        <button type="submit" class="btn btn-secondary">Select Vehicle</button>
                    </div>
                </form>
            </div>
            <div class="col-md-6">
                <div id="map" style="height:500px"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {required, minValue, requiredIf} from '@vuelidate/validators'
import useVuelidate from "@vuelidate/core";
import {useStore} from "vuex";
import {computed, ref, onMounted, defineEmits} from "vue";
import {setInfoStateComposable} from "@/composables/InfoState";
import {Loader} from '@googlemaps/js-api-loader';

export default {
    name: "RideInfo",
    setup(props, {emit}) {
        let rideInfo = ref({
            service_type: 'To Airport',
            hours: '',
            flight_number: '',
            pick_up_date: '',
            pick_up_time: '',
            pick_up_location: '',
            pick_up_zipcode: '',
            pick_up_location_cord: {},
            drop_location: '',
            drop_zipcode: '',
            drop_location_cord: {},
            number_of_passenger: 0,
            luggage_count: 0,
            distance: 0,
            child_count: 0,
            stop_points: []
        });
        let google;
        let suggestedAddress = ref({});
        const store = useStore();
        const v$ = useVuelidate();
        const stateRideInfo = computed(() => {
            return store.getters.getRideInfo;
        });

        const chooseVehicle = async () => {
            const isFormCorrect = await v$.value.$validate()
            if (!isFormCorrect) return
            store.commit('setRideInfo', rideInfo);
            // todo::use from composable
            store.commit('setInfoState', 2);
            //setinfoStateComposable(2)
            emit('infoState');
            //window.location.reload();
        }
        const syncRideInfo = () => {
            rideInfo.value = stateRideInfo.value
        }
        const crement = (v_modal, operation) => {
            if (operation === 'increment') {
                rideInfo.value[v_modal] += 1;
            } else if (rideInfo.value[v_modal] > 0 && operation === 'decrement') {
                rideInfo.value[v_modal] -= 1;
            }
        }
        const clearForm = () => {
            rideInfo.value.service_type = 'To Airport';
            rideInfo.value.hours = '';
            rideInfo.value.flight_number = '';
            rideInfo.value.pick_up_date = '';
            rideInfo.value.pick_up_time = '';
            rideInfo.value.pick_up_location = '';
            rideInfo.value.pick_up_zipcode = '';
            rideInfo.value.pick_up_location_cord = {};
            rideInfo.value.drop_location = '';
            rideInfo.value.drop_zipcode = '';
            rideInfo.value.drop_location_cord = {};
            rideInfo.value.number_of_passenger = 0;
            rideInfo.value.luggage_count = 0;
            rideInfo.value.distance = 0;
            rideInfo.value.child_count = 0;
            rideInfo.value.stop_points = [];
        }
        const stopLocationMap = (id, index) => {
            let pickUpLocation = document.getElementById(id);
            let getStopPointLocation = new google.maps.places.Autocomplete(pickUpLocation)
            google.maps.event.addListener(getStopPointLocation, 'place_changed', function () {
                var stopPointPlace = getStopPointLocation.getPlace();
                rideInfo.value.stop_points[index].value = stopPointPlace.name;
            });
        }
        const origin = (type) => {
            if (type === 'pickup') {
                let pickUpLocation = document.getElementById("pickUpLocation");
                let getPickUpLocation = new google.maps.places.Autocomplete(pickUpLocation)
                google.maps.event.addListener(getPickUpLocation, 'place_changed', function () {
                    var pickUpPlace = getPickUpLocation.getPlace();
                    let zipcode_condition = rideInfo.value.service_type
                    == "To Airport"
                    || rideInfo.value.service_type
                    == "Point-to-Point" ? 'administrative_area_level_1' : "postal_code";
                    for (var i = 0; i < pickUpPlace.address_components.length; i++) {
                        for (var j = 0; j < pickUpPlace.address_components[i].types.length; j++) {
                            if (pickUpPlace.address_components[i].types[j] == zipcode_condition) {
                                rideInfo.value.pick_up_zipcode = pickUpPlace.address_components[i].long_name;

                            }
                        }
                    }
                    rideInfo.value.pick_up_location = pickUpPlace.name;
                    rideInfo.value.pick_up_location_cord.lat = pickUpPlace.geometry.location.lat();
                    rideInfo.value.pick_up_location_cord.lng = pickUpPlace.geometry.location.lng();
                    initiateMap();
                });
            } else {
                let dropUpLocation = document.getElementById("dropUpLocation");
                let getDropUpLocation = new google.maps.places.Autocomplete(dropUpLocation)
                google.maps.event.addListener(getDropUpLocation, 'place_changed', function () {
                    var dropPlace = getDropUpLocation.getPlace();
                    let zipcode_condition = rideInfo.value.service_type == "To Airport" ? 'postal_code'
                        : "administrative_area_level_1";
                    for (var i = 0; i < dropPlace.address_components.length; i++) {
                        for (var j = 0; j < dropPlace.address_components[i].types.length; j++) {
                            if (dropPlace.address_components[i].types[j] == zipcode_condition) {
                                rideInfo.value.drop_zipcode = dropPlace.address_components[i].long_name;

                            }
                        }
                    }
                    rideInfo.value.drop_location = dropPlace.name;
                    rideInfo.value.drop_location_cord.lat = dropPlace.geometry.location.lat();
                    rideInfo.value.drop_location_cord.lng = dropPlace.geometry.location.lng();
                    initiateMap();
                });
            }
        }
        const setMap = () => {
            if (rideInfo.value.pick_up_location && rideInfo.value.drop_location) {
                initiateMap();
            }
        }
        const getLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showPosition);
            } else {
                alert("Geolocation is not supported by this browser.");
            }
        }
        const showPosition = (position) => {
            var lat = position.coords.latitude;
            var lng = position.coords.longitude;
            //map.setCenter(new google.maps.LatLng(lat, lng));
        }
        const initiateMap = () => {
            const map = new google.maps.Map(document.getElementById("map"), {
                center: new google.maps.LatLng(45.4215296, -75.6971931),
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
            });
            //TODO::need to remove set timeout
            setTimeout(() => {
                if (rideInfo.value.pick_up_location && rideInfo.value.drop_location) {
                    const directionsService = new google.maps.DirectionsService();
                    directionsService.route(
                        {
                            origin: rideInfo.value.pick_up_location,
                            destination: rideInfo.value.drop_location,
                            travelMode: 'DRIVING',
                            drivingOptions: {
                                departureTime: new Date(),  // for the time N milliseconds from now.
                                trafficModel: 'pessimistic'
                            }
                        },
                        (response, status) => {
                            rideInfo.value.distance = response.routes[0].legs[0].distance.value;
                            rideInfo.value.duration = response.routes[0].legs[0].distance.value;
                            if (status === "OK") {
                                new google.maps.DirectionsRenderer({
                                    suppressMarkers: false,
                                    directions: response,
                                    map: map,
                                    polylineOptions: {
                                        strokeColor: "red"
                                    }
                                });
                            }
                        }
                    )
                }
            }, 300);
        }
        const addStopPoint = () => {
            rideInfo.value.stop_points.push({value: ''});
        }
        const removeStopPoint = (index) => {
            rideInfo.value.stop_points.splice(index, 1);
        }
        const googleMapObjectInitiate = async () => {
            await new Loader({
                apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
                version: "weekly",
                libraries: ["places"]
            }).load().then(res => google = res)
        }
        onMounted(async () => {
            await googleMapObjectInitiate()
            initiateMap();
            getLocation();
            if (stateRideInfo.value.service_type) syncRideInfo();

        })
        return {
            rideInfo,
            v$,
            chooseVehicle,
            store,
            syncRideInfo,
            crement,
            emit,
            clearForm,
            origin,
            stopLocationMap,
            suggestedAddress,
            initiateMap,
            getLocation,
            showPosition,
            addStopPoint,
            removeStopPoint
        }
    },
    validations() {
        return {
            rideInfo: {
                service_type: {required},
                hours: {
                    required: requiredIf(function (abc) {
                        return this.rideInfo.service_type === 'Hourly'
                    })
                },
                flight_number: {
                    required: requiredIf(function (abc) {
                        return this.rideInfo.service_type === 'From Airport'
                    })
                },
                pick_up_date: {required},
                pick_up_time: {required},
                pick_up_location: {required},
                drop_location: {required},
                number_of_passenger: {
                    required,
                    minValue: minValue(1)
                },
                luggage_count: {
                    required,
                    minValue: minValue(0)
                },
                child_count: {
                    required,
                    minValue: minValue(0)
                },
                // stop_points: {
                //     value: {
                //         required
                //     }
                // }
            }

        }
    }
}
</script>

<style scoped>

</style>
