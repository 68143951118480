<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynav"
                    aria-controls="mynav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <router-link target="_blank" class="navbar-brand" :to="{name:'Home'}">
                <div class="d-flex">
                    <div class="d-flex align-items-center logo bg-purple">
                        <div class="fab fa-joomla h2 text-white"></div>
                    </div>
                    <div class="ms-3 d-flex flex-column">
                        <div class="h4">{{ siteName }}</div>
                        <div class="fs-6">Smart Booking App</div>
                    </div>
                </div>
            </router-link>
            <div class="collapse navbar-collapse" id="mynav">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <!--                    <li class="nav-item">
                                            <a class="nav-link active" aria-current="page" href="#">Categories <span
                                                class="fas fa-th-large px-1"></span></a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#">Exclusive</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#">Collections</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#">Blogs</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#">
                                                <div class="cart bg-purple">
                                                    <span class="fas fa-shopping-cart text-white"></span>
                                                </div>
                                            </a>
                                        </li>-->
                    <li class="nav-item">
                        <a class="nav-link" href="#" @click="logout()"> <span class="fas fa-user pe-2"></span> Logout</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script setup>
import logout from '@/composables/Auth';
import {ref} from "vue";

const siteName = ref(process.env.VUE_APP_TITLE)
</script>

<style scoped>

</style>
